<template>
    <div class="content-wrapper">
        <PageHeader screenName="Cadastrar nova" :linkItems="linkItems"/>

        <ConfirmModal 
            itemIdentifier="usuário"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirecionarLista"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirecionarLista"
        />

        <TimedAlert variant="success" message="Doença salva com sucesso!" :show="savedAlert" />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="doencaRules">
                    <b-form>
                        <b-row>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Nome técnico da doença*" 
                                label-for="nome-tecnico-doenca-input"
                                label-class="label_size_doenca"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="NomeTecnicoDoenca"
                                        rules="required"
                                    >
                                        <b-form-input 
                                            :state="errors.length > 0 || form.errorApi.nomeTecnico || form.errorApi.error ? false:null"
                                            id="nome-tecnico-doenca-input" 
                                            v-model="form.nomeTecnico"
                                            placeholder="Ex: Influenza"
                                            v-mask="('X'.repeat(250))"
                                        />
                                        <small class="text-muted">
                                            Nome conhecido no meio científico.
                                        </small>
                                        <br>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.nomeTecnico" class="text-danger">
                                            {{ form.errorApi.nomeTecnico }}
                                        </small>
                                        <small v-if="form.errorApi.error" class="text-danger">
                                            {{ form.errorApi.error }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Nome popular da doença" 
                                label-for="nome-popular-doenca-input"
                                label-class="label_size_doenca">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="NomePopularDoenca"
                                    >
                                        <b-form-input 
                                            :state="errors.length > 0 || form.errorApi.nomePopular ? false:null"
                                            id="nome-popular-doenca-input" 
                                            v-model="form.nomePopular"
                                            placeholder="Ex: Gripe"
                                            v-mask="('X'.repeat(250))"
                                        />
                                        <small class="text-muted">
                                            Nome conhecido popularmente.
                                        </small>
                                        <br>
                                        <small v-if="form.errorApi.nomePopular" class="text-danger">
                                            {{ form.errorApi.nomePopular }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="6" sm="12">
                                <b-form-group label="Descrição*" 
                                label-for="descricao-doenca-input"
                                label-class="label_size_doenca">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="DescricaoDoenca"
                                        rules="required"
                                    >
                                        <b-form-textarea 
                                            :state="errors.length > 0 || form.errorApi.descricao ? false:null"
                                            id="descricao-doenca-input" 
                                            rows="5"
                                            placeholder="Adicione uma descrição para esta doença"
                                            v-model="form.descricao"
                                            no-resize
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.descricao" class="text-danger">
                                            {{ form.errorApi.descricao }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </section>
            <section class="buttons">
                <b-button id="save-doenca" 
                    class="mr-2 mt-2" 
                    variant="custom-blue"
                    @click.prevent="validationForm(true)"
                    :disabled="saving || savingAndNew"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar doença</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="save-doenca" 
                    class="mr-2 mt-2" 
                    variant="custom-blue"
                    @click.prevent="validationForm(false)"
                    :disabled="saving || savingAndNew"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar e cadastrar uma nova</span>
                    <span v-if="savingAndNew" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-doenca" 
                    :disabled="saving || savingAndNew"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                    class="mt-2"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import handleErrorsService from './Services/HandleErrors'

export default {
    title: 'Cadastrar doença',

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, vSelect, ValidationProvider, ValidationObserver,
        TimedAlert, PageHeader, ConfirmModal, SucessoModal
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Doenças',
                    routeName: 'doenca-list'
                },
                {
                    name: 'Cadastrar nova',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            required,
            savedAlert: false,
            saving: false,
            savingAndNew: false,
            form: {
                nomeTecnico: '',
                nomePopular: '',
                descricao: '',
                errorApi: {
                    nomeTecnico: '',
                    nomePopular: '',
                    descricao: '',
                    error: ''
                }
            }
        }
    },

    methods: {
        validationForm(redirectToList) {
            this.$refs.doencaRules.validate().then(success => {
                if (success) {
                    if(redirectToList){
                        this.saving = true;
                    }else{
                        this.savingAndNew = true;
                    }
                    this.saveDoenca(redirectToList);
                }
            })
        },

        saveDoenca(redirectToList) {
            const parameters = {
                'nome_tecnico': this.form.nomeTecnico,
                'nome_popular': this.form.nomePopular,
                'descricao': this.form.descricao,
            }

            this.$http.post(this.$api.saveAndGetDoenca(), parameters).then(() => {
                if(redirectToList) {
                    this.openModalSucesso();
                }else{
                    this.savedAlert = !this.savedAlert;
                }

                this.saving = false;
                this.savingAndNew = false;
                this.cleanForm();

            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosDoenca(data, this.form.errorApi);

                this.saving = false;
                this.savingAndNew = false;
            })
        },

        cleanForm() {
            this.form.nomeTecnico = '';
            this.form.nomePopular = '';
            this.form.descricao = '';

            this.form.errorApi.nomeTecnico = '';
            this.form.errorApi.nomePopular = '';
            this.form.errorApi.descricao = '';
            this.form.errorApi.error = ''

            this.$refs.doencaRules.reset();
        },

        redirecionarLista() {
            this.$router.push({ name: 'doenca-list' });
        },

        openModalConfirm() {
            this.modal.showModal = true;
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },

        openModalSucesso() {
            this.modalSucesso.showModal = true;
        }
    }
}
</script>

